<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm
          ref="freightsRef"
          :fields="this.freight.fields"
          :card="this.freight.card"
          @change="freightChange"
        >
          <template v-slot:footer>
            <b-button
              variant="primary"
              class="mt-4 float-right"
              @click="addNewFreight"
              >Save</b-button
            >
          </template>
        </BrunaForm>
      </b-col>
    </b-row>
    <b-row>
      <b-col> </b-col>
    </b-row>

    <ag-grid-table
      ref="grid-historico-freights"
      :configUrl="historicoFreightsConfig"
      :dataUrl="historicoFreightsData"
      :editUrl="historicoFreightsEdit"
      :actions="actions"
    ></ag-grid-table>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";



import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";



export default {
  data()
  {
    return {


      historicoFreightsConfig: useApiServices.historicoFreightsConfig,
      historicoFreightsData: useApiServices.historicoFreightsData,
      historicoFreightsEdit: useApiServices.historicoFreightsEdit,

      actions: [
        { name: "Eliminar", icon: "Trash2Icon", action: this.actionEliminar },
      ],


      freight: {
        card: {
          title: "Agregar nuevo flete",
          subtitle: "",
          tooltip: ""
        },
        fields: [


          {
            label: "Destination Country",
            type: "select",
            id: 'country_destination',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Chile", text: "Chile" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Destination Port",
            type: "select",
            id: 'port_destination',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Chile", text: "Chile" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Fresh USD/KG Net",
            type: "input",
            id: 'fresco_usd_kg_net',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Frozen USD/KG Net",
            type: "input",
            id: 'congelado_usd_kg_net',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Warehouse Cost USD/KG",
            type: "input",
            id: 'warehouse_cost_usd_kg',
            rules: 'numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Customs Cost USD/KG",
            type: "input",
            id: 'customs_cost_usd_kg',
            rules: 'numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Other Expenses USD/KG",
            type: "input",
            id: 'other_expenses_usd_kg',
            rules: 'numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


        ]
      },








    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
    ToastificationContent
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {

  },


  created()
  {

    //this.loadCountries()



  },

  methods: {

    actionEliminar(params)
    {
      console.log(params)


      this.$bvModal.msgBoxConfirm('Confirma si quieres eliminar el registro', {
        title: 'Confirmar',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Eliminar',
        cancelTitle: 'Cancelar',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value =>
        {

          if (value)
          {
            useApiServices.postFreightDelete(params.data)
              .then((response) =>
              {

                console.log(response);

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Eliminación Exitosa`,
                    icon: "UploadIcon",
                    variant: "success",
                    html: `El registro se ha eliminado.`,
                  },
                });

                this.$refs['grid-historico-freights'].refreshRows([], false)


              })
              .catch((error) =>
              {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Ocurrió un Error`,
                    icon: "UploadIcon",
                    variant: "danger",
                    html: `Ha ocurrido un error</b>.`,
                  },
                });

              });


          }


        })
        .catch(err =>
        {
          // An error occurred
          console.log(err)
        })


    },


    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },



    loadCountries()
    {


      useApiServices.getCountries(this).then((response) =>
      {
        let countries_options = [
          { value: null, text: "Selecciona una opcion" }
        ]

        response.data.forEach(item =>
        {

          countries_options.push(
            {
              value: item, text: item.name
            })

        });

        this.findFieldByName(this.freight.fields, 'country_destination').options = countries_options;




      })
        .catch((error) =>
        {
          console.log(error);

        });





    },

    freightChange(field)
    {
      console.log(field)

      if (field.id == "country_destination")
      {
        /*

        let destination_ports_options = [{ value: null, text: "Selecciona una opcion" }];

        field.value.ports.forEach(port =>
        {
          destination_ports_options.push({ value: port, text: port.name })

        })



        this.findFieldByName(this.freight.fields, 'port_destination').options = destination_ports_options
*/
      }


    },



    addNewFreight()
    {
      let data = this.freight.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})

      console.log({ data })

      Promise.all([
        this.$refs.freightsRef.validate(),
      ]).then(([freightValid]) =>
      {
        if (freightValid)
        {



          useApiServices.postFreightsCreate(data, this)
            .then((response) =>
            {

              this.$refs['grid-historico-freights'].refreshRows()

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });

            })
            .catch((error) =>
            {

              console.log(error.response)

              let message = error.response.data.message;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });
        }
      });


    }


  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

